//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from 'vue';
import { Icon } from 'vant';
import { mapState } from 'vuex';

const avatars = require('@/assets/icon/ava-1.png');

Vue.use(Icon);
export default Vue.extend({
  name: 'UserMy',
  data() {
    return {
      showNickName: false,
      showEmail: false,
      name: '',
      email: '',
      code: '',
      avatars,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    handleShowNickName() {
      this.showNickName = true;
      this.showEmail = false;
    },
    handleShowEmail() {
      this.showEmail = true;
      this.showNickName = false;
    },
  },
});
